import type { FC } from 'react';
import { useEffect, useState } from 'react';
import EditFolder from './editfolder';
interface folderitemProps {
  view: string;
  folder: any;
  selectFolder: (path: string) => void;
  groups: Array<any>;
  refresh: () => void;
}

const FolderItem: FC<folderitemProps> = ({
  view,
  folder,
  selectFolder,
  groups,
  refresh,
}) => {
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [groupColours, setGroupColours] = useState<Array<any>>([]);
  const [hideItem, setHideItem] = useState(false);

  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('user') || '{}')
  );
  const [visibility, setVisibility] = useState<Array<number>>([0, 1]);
  const [popUp, setPopup] = useState(false);

  // Assuming you have a state to track the dragged folder ID

  const [dragOverFolder, setDragoverFolder] = useState<number | null>(null);

  const handleDragStart = (
    e: React.DragEvent<HTMLDivElement>,
    folderId: number
  ) => {
    e.dataTransfer.setData('text/plain', folderId.toString());
  };

  const reorderRank = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const folderId = e.dataTransfer.getData('text/plain');

    await fetch(process.env.REACT_APP_API_URL + '/update-folder-rank.php', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('jwt'),
      },
      body: JSON.stringify({
        folder_id: folderId,
        hovered_folder_id: dragOverFolder,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          alert(data.error);
        } else {
          setDragoverFolder(null);
          refresh();
        }
      })
      .catch((error) => {
        // Handle network or server errors
        console.log('error');
        console.log(error);
      });
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const targetFolderId = e.currentTarget.getAttribute('data-id');
    setDragoverFolder(targetFolderId ? parseInt(targetFolderId) : null);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragoverFolder(null); // Reset the state
  };

  const deleteFolder = async (id: number) => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this file?'
    );
    if (!confirmDelete) {
      return;
    }
    setLoadingDelete(true);

    const storedJWT = localStorage.getItem('jwt');

    await fetch(process.env.REACT_APP_API_URL + '/delete-folder.php', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + storedJWT,
      },
      body: JSON.stringify({
        id: id,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          alert(data.error);
          setLoadingDelete(false);
        } else {
          setHideItem(true);
        }
      })
      .catch((error) => {
        // Handle network or server errors
        console.log('error');
        console.log(error);
        alert(error);
        setLoadingDelete(false);
      });
  };

  const date = new Date(folder.date);

  const readableDate = date.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });

  const getGroupColour = async (groupID: number) => {
    const storedJWT = localStorage.getItem('jwt');

    await fetch(process.env.REACT_APP_API_URL + '/get-group-colour.php', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + storedJWT,
      },
      body: JSON.stringify({
        id: groupID,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setGroupColours((groupColours) => [...groupColours, data]);
        return;
      })
      .catch((error) => {
        // Handle network or server errors
        console.log('error');
        console.log(error);
      });
  };

  useEffect(() => {
    const vis_array = JSON.parse(folder.access_level);

    setGroupColours([]);
    vis_array.map((groupID: number) => {
      if (groupID !== 0 && groupID !== 1) {
        getGroupColour(groupID);
      }
    });
    setVisibility(vis_array);
  }, []);

  const roles = JSON.parse(user.groups || '[]');

  return (
    !hideItem && (
      <>
        {view === 'list' ? (
          <div
            draggable
            onDragStart={(e) => handleDragStart(e, folder.ID)} // Replace 1 with the actual folder ID
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={reorderRank}
            className={`flex justify-between w-full col-span-4 fade-in px-2 transition-all group xl:hover:bg-slate-100 ${
              dragOverFolder === folder.ID ? 'bg-slate-100' : ''
            }`}
            data-id={folder.ID}
          >
            <div className={`flex mb-2 md:mb-0 `}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                viewBox="0 0 256 250"
                xmlSpace="preserve"
                className={` ${
                  view === 'list' ? ' min-w-[47px] w-12 h-12' : 'w-32 h-30'
                } mr-3 md:mr-5   transition-fill cursor-pointer`}
                onClick={() => selectFolder(folder.path)}
              >
                <defs></defs>
                <g
                  style={{
                    stroke: 'none',
                    strokeWidth: 0,
                    strokeDasharray: 'none',
                    strokeLinecap: 'butt',
                    strokeLinejoin: 'miter',
                    strokeMiterlimit: 10,
                    fill: 'none',
                    fillRule: 'nonzero',
                    opacity: 1,
                  }}
                  transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
                >
                  <path
                    d="M 3.649 80.444 h 82.703 c 2.015 0 3.649 -1.634 3.649 -3.649 v -56.12 c 0 -2.015 -1.634 -3.649 -3.649 -3.649 H 35.525 c -1.909 0 -3.706 -0.903 -4.846 -2.435 l -2.457 -3.301 c -0.812 -1.092 -2.093 -1.735 -3.454 -1.735 H 3.649 C 1.634 9.556 0 11.19 0 13.205 v 63.591 C 0 78.81 1.634 80.444 3.649 80.444 z"
                    style={{
                      stroke: 'none',
                      strokeWidth: 1,
                      strokeDasharray: 'none',
                      strokeLinecap: 'butt',
                      strokeLinejoin: 'miter',
                      strokeMiterlimit: 10,
                      fill: '#e2e8f0', // Changed color
                      fillRule: 'nonzero',
                      opacity: 1,
                    }}
                    transform=" matrix(1 0 0 1 0 0) "
                    strokeLinecap="round"
                  />
                  <path
                    d="M 86.351 80.444 H 3.649 C 1.634 80.444 0 78.81 0 76.795 V 29.11 c 0 -2.015 1.634 -3.649 3.649 -3.649 h 82.703 c 2.015 0 3.649 1.634 3.649 3.649 v 47.685 C 90 78.81 88.366 80.444 86.351 80.444 z"
                    style={{
                      stroke: 'none',
                      strokeWidth: 1,
                      strokeDasharray: 'none',
                      strokeLinecap: 'butt',
                      strokeLinejoin: 'miter',
                      strokeMiterlimit: 10,
                      fill: '#cbd5e1', // Changed color
                      fillRule: 'nonzero',
                      opacity: 1,
                    }}
                    transform=" matrix(1 0 0 1 0 0) "
                    strokeLinecap="round"
                  />
                  <path
                    d="M 85.106 76.854 H 4.894 c -0.276 0 -0.5 -0.224 -0.5 -0.5 s 0.224 -0.5 0.5 -0.5 h 80.213 c 0.276 0 0.5 0.224 0.5 0.5 S 85.383 76.854 85.106 76.854 z"
                    style={{
                      stroke: 'none',
                      strokeWidth: 1,
                      strokeDasharray: 'none',
                      strokeLinecap: 'butt',
                      strokeLinejoin: 'miter',
                      strokeMiterlimit: 10,
                      fill: '#fafbfa', // Changed color
                      fillRule: 'nonzero',
                      opacity: 1,
                    }}
                    transform=" matrix(1 0 0 1 0 0) "
                    strokeLinecap="round"
                  />
                  <path
                    d="M 85.106 72.762 H 4.894 c -0.276 0 -0.5 -0.224 -0.5 -0.5 s 0.224 -0.5 0.5 -0.5 h 80.213 c 0.276 0 0.5 0.224 0.5 0.5 S 85.383 72.762 85.106 72.762 z"
                    style={{
                      stroke: 'none',
                      strokeWidth: 1,
                      strokeDasharray: 'none',
                      strokeLinecap: 'butt',
                      strokeLinejoin: 'miter',
                      strokeMiterlimit: 10,
                      fill: '#e2e8f0', // Changed color
                      fillRule: 'nonzero',
                      opacity: 1,
                    }}
                    transform=" matrix(1 0 0 1 0 0) "
                    strokeLinecap="round"
                  />
                </g>
              </svg>
              <div
                className={`flex overflow-hidden max-w-full  flex items-center `}
              >
                <p
                  className={`relative text-default text-xs md:text-sm overflow-hidden overflow-ellipsis max-h-[37px] md:max-h-auto`}
                >
                  {folder.name}
                </p>
              </div>
            </div>
            <div className={`${'flex items-center'}`}>
              {(roles.includes(0) || roles.includes(1)) && (
                <>
                  {loadingDelete ? (
                    <svg
                      className={`${
                        view !== 'list' && 'absolute top-2 left-2'
                      } text-white w-7 h-7 animate-spin hidden xl:group-hover:inline-block`}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25 "
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="#ffffff"
                      className={` ${
                        view === 'list'
                          ? 'mr-2 md:mr-5'
                          : 'absolute top-2 left-2'
                      } p-1 transition-colors lg:hidden bg-opacity-50 rounded-md cursor-pointer h-6 h-6 md:w-7 md:h-7  bg-slate-700 hover:bg-red-700 xl:group-hover:inline-block`}
                      onClick={() => deleteFolder(folder.ID)}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                  )}

                  {visibility.length > 1 && (
                    <div className="relative flex-wrap content-center justify-end hidden gap-1 mr-5 md:flex">
                      {(roles.includes(0) || roles.includes(1)) && (
                        <div
                          className={`relative ${
                            view !== 'list' ? 'mb-2' : 'mr-5'
                          } flex gap-1`}
                        >
                          {groupColours.map((item) => (
                            <div
                              key={`group-colour-${item.ID}`}
                              className={`w-3 h-3 rounded-full cursor-pointer group/groups hover:opacity-80 transition-all relative`}
                              style={{ backgroundColor: item.colour }}
                            >
                              <p className="hidden group-hover/groups:block absolute bottom-4 px-2 py-0.5 rounded bg-white text-xs left-1/2 shadow -translate-x-2/4">
                                {item.name}
                              </p>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}

              <p
                className={`relative ${
                  view === 'list' && 'hidden lg:grid content-center'
                }
       text-xs text-gray-500`}
              >
                {readableDate}
              </p>
            </div>
          </div>
        ) : (
          <div
            className={`
          ${
            view === 'small' &&
            'h-[180px] lg:h-[200px] col-span-1 lg:col-span-2 xl:col-span-1'
          }
          ${view === 'medium' && 'h-[290px] lg:h-[400px]  col-span-2'} 
          ${view === 'large' && 'h-[250px] md:h-[500px]  col-span-4'} 
              
          relative overflow-hidden rounded-md group grid shadow-md hover:shadow-none transition-all content-end cursor-pointer`}
          >
            {folder.base64 ? (
              <img
                src={`data:image/jpeg;base64,${folder.base64}`}
                alt={folder.name}
                className="absolute object-cover w-full h-full"
                onClick={() => selectFolder(folder.path)}
              />
            ) : (
              <img
                src={`/image-placeholder.jpg`}
                alt={folder.name}
                className="absolute object-cover w-full h-full"
                onClick={() => selectFolder(folder.path)}
              />
            )}
            {(roles.includes(0) || roles.includes(1)) && (
              <>
                {visibility.length > 1 && (
                  <div className="absolute right-0 w-full gap-1 pl-10 pr-2 top-2">
                    {(roles.includes(0) || roles.includes(1)) && (
                      <div
                        className={`relative ${
                          view !== 'list' ? 'mb-2' : 'mr-5'
                        } flex flex-wrap flex flex-wrap content-center justify-end gap-1`}
                      >
                        {groupColours.map((item) => (
                          <div
                            key={`group-colour-${item.ID}`}
                            className={`w-3 h-3 border border-white rounded-full cursor-pointer transition-all relative`}
                            style={{ backgroundColor: item.colour }}
                          ></div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
                {loadingDelete ? (
                  <svg
                    className={`block absolute top-0 left-0 text-white h-5 w-5 md:w-8 md:h-8 animate-spin`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25 "
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="#ffffff"
                    className={`absolute top-0 left-0 p-1.5 transition-colors group-hover:block xl:hidden bg-opacity-50 rounded-br-md overflow-hidden cursor-pointer h-7 w-7 md:w-8 md:h-8  bg-slate-700 hover:bg-red-700`}
                    onClick={() => deleteFolder(folder.ID)}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                    />
                  </svg>
                )}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="white"
                  onClick={() => setPopup(true)}
                  className="absolute top-12 left-0 p-1.5 transition-colors group-hover:block xl:hidden bg-opacity-50 rounded-tr-md rounded-br-md overflow-hidden cursor-pointer h-7 w-7 md:w-8 md:h-8  bg-slate-700 hover:bg-sky-600"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                  />
                </svg>
              </>
            )}

            <div className="relative ">
              <p
                className={`${
                  (view === 'small' || view === 'medium') &&
                  'text-xs lg:text-xs'
                } text-xs lg:text-base bg-default text-white rounded-tr-md px-3 py-2 w-auto inline-block`}
              >
                {folder.name}
              </p>
            </div>
          </div>
        )}
        {popUp && (
          <div className="fixed top-0 left-0 z-10 grid items-center content-center w-full h-full bg-black bg-opacity-50">
            <EditFolder
              refresh={refresh}
              closePopup={() => setPopup(false)}
              folder={folder}
            />
          </div>
        )}
      </>
    )
  );
};
export default FolderItem;
