import React, { FC, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContext from '../utils/appContext';
import Icon from '../components/icons';
import Navigation from '../components/navigation';
//make an infterface for children props
interface LayoutProps {
  children: React.ReactNode;
}
const Layout: FC<LayoutProps> = ({ children }) => {
  const navigate = useNavigate();
  const context = useContext(AppContext);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('user') || '{}')
  );
  const logout = () => {
    localStorage.clear();
    context?.updateLoginStatus(false);
    navigate('/login');
  };
  return (
    <main className="">
      <div className="flex justify-start fade-in">
        <div className="lg:max-w-[300px] lg:w-[300px] bg-slate-100">
          <Navigation />
        </div>
        <div className="w-full overflow-x-hidden max-w-7xl">
          <div className="flex justify-end px-5 py-3 border-b border-slate-100 md:pl-5 lg:pl-10 xl:pl-20 md:py-7 md:pr-7">
            <div className="flex items-center">
              <p
                className="text-xs font-medium leading-6 md:text-sm "
                aria-hidden="true"
              >
                {user?.firstname} {user?.lastname}
              </p>
              <p className="ml-2 text-slate-300">|</p>

              <a
                onClick={logout}
                className={`cursor-pointer hover:text-slate-500
                    group  gap-x-2 p-2 text-sm leading-6 flex justify-center lg:justify-start items-center`}
              >
                <Icon iconName={`logout`} />
                <span className="hidden lg:block">Logout</span>
              </a>
            </div>
          </div>
          {children}
        </div>
      </div>
    </main>
  );
};
export default Layout;
