import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';

const ContactPage: FC = ({}) => {
  //get the product id from the url
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [contact, setContact] = useState<any>({});
  const [contactList, setContactList] = useState<any>([]);

  const getContact = async (id: number) => {
    const storedJWT = localStorage.getItem('jwt');
    await fetch(process.env.REACT_APP_API_URL + '/get-contact-by-id.php', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + storedJWT,
      },
      body: JSON.stringify({
        id: id,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data[0]) {
          setContact(data[0]);

          let listids = JSON.parse(data[0].contact_list);

          getContactLists(listids);
        }
        setLoading(false);
      })
      .catch((error) => {
        // Handle network or server errors
        console.log('error', error);
        setLoading(false);
      });
  };

  const getContactLists = async (listIds: Array<number>) => {
    const storedJWT = localStorage.getItem('jwt');
    await fetch(process.env.REACT_APP_API_URL + '/get-contact-list-names.php', {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: 'Bearer ' + storedJWT,
      },
      body: JSON.stringify({
        ids: listIds,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setContactList(data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (id) {
      getContact(parseInt(id));
    }
  }, []);

  return (
    <div className="p-3 mb-10 fade-in lg:py-10 lg:pr-10 md:mb-0 md:px-5 lg:pl-10 xl:pl-16">
      {loading && (
        <div className="flex justify-center">
          <svg
            className="inline-block mt-5 w-9 h-9 text-accent animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      )}

      <div>
        <div className="px-4 sm:px-0">
          <div className="items-end px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <div className=" w-[150px] h-[180px] relative">
              {contact.base64 ? (
                <img
                  src={`data:image/jpeg;base64,${contact.base64}`}
                  alt={contact.firstname + ' ' + contact.lastname}
                  className="object-cover w-full h-full rounded-lg bg-gray-50"
                />
              ) : (
                <img
                  src={`../contact-image-placeholder.jpg`}
                  alt={contact.firstname + ' ' + contact.lastname}
                  className="object-cover w-full h-full rounded-lg bg-gray-50"
                />
              )}
            </div>
            <div>
              <h3 className="text-base font-semibold leading-7 text-gray-900">
                {contact.firstname} {contact.lastname}
              </h3>
              <p className="max-w-2xl mt-1 text-sm leading-6 text-gray-500">
                {contact.title}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-6 border-t border-gray-100">
          <dl className="divide-y divide-gray-100">
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Company
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {contact.company}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                E-mail
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {contact.email}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Phone
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {contact.phone}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Contact lists
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {contactList.length > 0 &&
                  contactList
                    .map((item: any, index: number) => item.name)
                    .join(' / ')}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                About
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {contact.description}
              </dd>
            </div>
          </dl>
        </div>
      </div>

      <div className="mt-10">
        <button
          className="flex outline-none text-default hover:text-slate-700"
          onClick={() => navigate('/contacts')}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
            />
          </svg>
          <span className="ml-1 text-sm">back</span>
        </button>
      </div>
    </div>
  );
};
export default ContactPage;
