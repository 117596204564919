import type { FC } from 'react';
import React, { useEffect, useContext } from 'react';
import AppContext from '../utils/appContext';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import FileUpload from '../components/fileupload';
import CreateFolder from '../components/createfolder';
import FileBrowser from '../components/filebrowser';
import SearchFiles from '../components/searchfiles';
import FileSats from '../components/filestats';
//files interface
interface filesProps {}
const Files: FC<filesProps> = ({}) => {
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('user') || '{}')
  );
  const [folderPath, setFolderPath] = useState<string>('/');
  const [counter, setCounter] = useState(0);
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const [groups, setGroups] = useState<Array<any>>([]);
  const checkStatus = async () => {
    const storedJWT = localStorage.getItem('jwt');

    if (storedJWT) {
      await fetch(process.env.REACT_APP_API_URL + '/check-status.php', {
        method: 'GET',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + storedJWT,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data == 'success') {
            console.log('success');
            setLoading(false);
            setPageLoaded(true);
          } else {
            localStorage.clear();
            context?.updateLoginStatus(false);
            navigate('/login');
          }
        })
        .catch((error) => {
          // Handle network or server errors
          console.log('error');
          console.log(error);
          localStorage.clear();
          context?.updateLoginStatus(false);
          navigate('/login');
        });
    } else {
      console.log('not logged in');
      localStorage.clear();
      context?.updateLoginStatus(false);
      navigate('/login');
    }
  };
  const updateFolder = () => {
    console.log('update folder');
    setCounter((counter) => counter + 1);
  };
  const updateFolderPath = (path: string) => {
    setFolderPath(path);
  };
  const getgroups = async () => {
    const storedJWT = localStorage.getItem('jwt');
    await fetch(process.env.REACT_APP_API_URL + '/get-all-groups.php', {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: 'Bearer ' + storedJWT,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setGroups(data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const [activeTab, setActiveTab] = useState(0);
  const toggleActiveTab = () => {
    setActiveTab((prevTab) => (prevTab === 0 ? 1 : 0));
  };
  useEffect(() => {
    if (user.groups?.includes(0) || user.groups?.includes(1)) {
      getgroups();
    }
    checkStatus();
  }, []);
  const roles = JSON.parse(user.groups || '[]');

  return (
    pageLoaded && (
      <>
        {activeTab === 0 ? (
          <>
            <div className="relative z-10 p-3 md:pt-10 md:p-5 lg:pl-10 xl:pl-16 fade-in">
              <div className="flex rounded-xl bg-slate-400">
                <div className="w-full lg:w-[50%] xl:w-[40%] p-5 md:p-10 lg:p-10 xl:py-12 xl:px-16">
                  <h2 className="py-2 text-base text-white md:text-xl ">
                    Welcome {user.firstname},<br />
                    browse our brand or product images
                  </h2>
                  <div className="my-3 max-w-[220px] ">
                    <SearchFiles
                      updateFolderPath={updateFolderPath}
                      path={folderPath}
                    />
                  </div>
                </div>
                <div className="relative  hidden lg:block lg:w-[50%] xl:w-[60%]">
                  <img
                    src="/welcome-page.jpg"
                    alt=""
                    className="absolute object-cover w-full h-full rounded-tr-xl rounded-br-xl"
                  />
                </div>
              </div>
            </div>

            <FileBrowser
              toggleActiveTab={toggleActiveTab}
              folderPath={folderPath}
              changeFolderPath={updateFolderPath}
              refreshCounter={counter}
              groups={groups}
            />

            <div className="grid max-w-3xl gap-10 p-3 mb-10 lg:grid-cols-2 md:mb-0 md:px-5 lg:pl-10 xl:pl-16">
              {(roles.includes(0) || roles.includes(1)) && (
                <FileUpload
                  updateFolder={updateFolder}
                  folderPath={folderPath}
                  groups={groups}
                />
              )}
              {(roles.includes(0) || roles.includes(1)) && (
                <CreateFolder
                  updateFolderPath={updateFolderPath}
                  folderPath={folderPath}
                  groups={groups}
                />
              )}
            </div>
          </>
        ) : (
          <div className="relative z-10 p-3 lg:pb-20 md:pt-10 md:p-5 lg:pl-10 xl:pl-16 fade-in">
            <FileSats toggleActiveTab={toggleActiveTab} />
          </div>
        )}
      </>
    )
  );
};
export default Files;
