export default `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html
  xmlns="http://www.w3.org/1999/xhtml"
  xmlns:o="urn:schemas-microsoft-com:office:office"
>
  <head>
    <meta charset="UTF-8" />
    <meta content="width=device-width, initial-scale=1" name="viewport" />
    <meta name="x-apple-disable-message-reformatting" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta content="telephone=no" name="format-detection" />
    <title></title>
    <!--[if (mso 16)]>
      <style type="text/css">
        a {
          text-decoration: none;
        }
      </style>
    <![endif]-->
    <!--[if gte mso 9
      ]><style>
        sup {
          font-size: 100% !important;
        }
      </style><!
    [endif]-->
    <!--[if gte mso 9]>
      <xml>
        <o:OfficeDocumentSettings>
          <o:AllowPNG></o:AllowPNG>
          <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
      </xml>
    <![endif]-->
  </head>
  <body>
    <div class="es-wrapper-color">
      <!--[if gte mso 9]>
        <v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t">
          <v:fill type="tile" color="#f6f6f6"></v:fill>
        </v:background>
      <![endif]-->
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0">
        <tbody>
          <tr>
            <td class="esd-email-paddings" valign="top">
              <table
                class="es-content esd-header-popover"
                cellspacing="0"
                cellpadding="0"
                align="center"
              >
                <tbody>
                  <tr></tr>
                  <tr>
                    <td
                      class="es-adaptive esd-stripe"
                      esd-custom-block-id="8589"
                      align="center"
                    >
                      <table
                        class="es-header-body"
                        width="600"
                        cellspacing="0"
                        cellpadding="0"
                        align="center"
                      >
                        <tbody>
                          <tr>
                            <td
                              class="esd-structure es-p15t es-p20b es-p20r es-p20l"
                              style="background-color: #ffffff"
                              bgcolor="#ffffff"
                              align="left"
                            >
                              <!--[if mso]><table width="560" cellpadding="0" cellspacing="0"><tr><td width="174" valign="top"><![endif]-->
                              <table
                                class="es-left"
                                cellspacing="0"
                                cellpadding="0"
                                align="left"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      class="es-m-p0r esd-container-frame"
                                      width="174"
                                      valign="top"
                                      align="center"
                                    >
                                      <table
                                        width="100%"
                                        cellspacing="0"
                                        cellpadding="0"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              class="esd-block-image es-m-p0l es-m-txt-c"
                                              align="left"
                                              style="font-size: 0px"
                                            >
                                              <a href="#"
                                                ><img
                                                  src="https://eqgda.stripocdnplugin.email/content/fc8ac2a295024cc28c614f734b7d34ac/lib/pluginId_fc8ac2a295024cc28c614f734b7d34ac_email_123/suzylogo.png"
                                                  alt
                                                  style="display: block"
                                                  width="118"
                                              /></a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td><td width="20"></td><td width="366" valign="top"><![endif]-->
                              <table
                                cellspacing="0"
                                cellpadding="0"
                                align="right"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      class="esd-container-frame"
                                      width="366"
                                      align="left"
                                    >
                                      <table
                                        style="
                                          border-left: 2px solid #808080;
                                          border-right: 2px solid #808080;
                                          border-top: 2px solid #808080;
                                          border-bottom: 2px solid #808080;
                                        "
                                        width="100%"
                                        cellspacing="0"
                                        cellpadding="0"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              class="esd-block-menu"
                                              esd-img-prev-h="16"
                                              esd-img-prev-w="16"
                                            >
                                              <table
                                                class="es-menu"
                                                width="100%"
                                                cellspacing="0"
                                                cellpadding="0"
                                              >
                                                <tbody>
                                                  <tr class="links">
                                                    <td
                                                      class="es-p10t es-p10b es-p5r es-p5l"
                                                      style="
                                                        padding-bottom: 10px;
                                                        padding-top: 10px;
                                                      "
                                                      id="esd-menu-id-0"
                                                      width="25.00%"
                                                      bgcolor="#f0f0f0"
                                                      align="center"
                                                    >
                                                      <a
                                                        target="_blank"
                                                        style="color: #333333"
                                                        href="https://stripo.email/"
                                                        >Service1</a
                                                      >
                                                    </td>
                                                    <td
                                                      class="es-p10t es-p10b es-p5r es-p5l"
                                                      style="
                                                        padding-bottom: 10px;
                                                        padding-top: 10px;
                                                      "
                                                      id="esd-menu-id-1"
                                                      esdev-border-color="#000000"
                                                      esdev-border-style="solid"
                                                      width="25.00%"
                                                      bgcolor="#f0f0f0"
                                                      align="center"
                                                    >
                                                      <a
                                                        target="_blank"
                                                        style="color: #333333"
                                                        href="https://stripo.email/"
                                                        >Service2</a
                                                      >
                                                    </td>
                                                    <td
                                                      class="es-p10t es-p10b es-p5r es-p5l"
                                                      style="
                                                        padding-bottom: 10px;
                                                        padding-top: 10px;
                                                      "
                                                      id="esd-menu-id-2"
                                                      esdev-border-color="#000000"
                                                      esdev-border-style="solid"
                                                      width="25.00%"
                                                      bgcolor="#f0f0f0"
                                                      align="center"
                                                    >
                                                      <a
                                                        target="_blank"
                                                        style="color: #333333"
                                                        href="https://stripo.email/"
                                                        >Service3</a
                                                      >
                                                    </td>
                                                    <td
                                                      class="es-p10t es-p10b es-p5r es-p5l"
                                                      style="
                                                        padding-bottom: 10px;
                                                        padding-top: 10px;
                                                      "
                                                      id="esd-menu-id-3"
                                                      esdev-border-color="#000000"
                                                      esdev-border-style="solid"
                                                      width="25.00%"
                                                      bgcolor="#f0f0f0"
                                                      align="center"
                                                    >
                                                      <a
                                                        target="_blank"
                                                        style="color: #333333"
                                                        href="https://stripo.email/"
                                                        >Service4</a
                                                      >
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td></tr></table><![endif]-->
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                class="es-content"
                cellspacing="0"
                cellpadding="0"
                align="center"
              >
                <tbody>
                  <tr>
                    <td class="esd-stripe" align="center">
                      <table
                        class="es-content-body"
                        width="600"
                        cellspacing="0"
                        cellpadding="0"
                        bgcolor="#ffffff"
                        align="center"
                      >
                        <tbody>
                          <tr>
                            <td
                              class="esd-structure es-p20r es-p20l"
                              align="left"
                            >
                              <table
                                width="100%"
                                cellspacing="0"
                                cellpadding="0"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      class="esd-container-frame"
                                      width="560"
                                      valign="top"
                                      align="center"
                                    >
                                      <table
                                        width="100%"
                                        cellspacing="0"
                                        cellpadding="0"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              class="esd-block-image"
                                              style="font-size: 0px"
                                            >
                                              <a target="_blank"
                                                ><img
                                                  class="adapt-img esdev-stretch-width"
                                                  src="https://eqgda.stripocdnplugin.email/content/fc8ac2a295024cc28c614f734b7d34ac/lib/pluginId_fc8ac2a295024cc28c614f734b7d34ac_email_123/sampleimage.jpg"
                                                  alt
                                                  style="display: block"
                                                  width="560"
                                              /></a>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              class="esd-block-text es-p20t es-p5b"
                                              align="left"
                                            >
                                              <h2>TITLE HERE</h2>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              class="esd-block-text es-m-txt-c es-p10b"
                                              align="left"
                                            >
                                              <p style="color: #333333">
                                                Lorem ipsum dolor sit amet,
                                                consectetur adipiscing elit.
                                                Pellentesque vitae interdum
                                                ligula. Pellentesque feugiat
                                                ligula ligula, in interdum dolor
                                                aliquet et. Aliquam vitae sem
                                                eget erat viverra malesuada.
                                              </p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              class="esd-block-text es-m-txt-c"
                                              align="left"
                                            >
                                              <p style="color: #333333">
                                                Lorem ipsum dolor sit amet,
                                                consectetur adipiscing elit.
                                                Pellentesque vitae interdum
                                                ligula. Pellentesque feugiat
                                                ligula ligula, in interdum dolor
                                                aliquet et. Aliquam vitae sem
                                                eget erat viverra malesuada.
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                class="es-content"
                cellspacing="0"
                cellpadding="0"
                align="center"
              >
                <tbody>
                  <tr>
                    <td class="esd-stripe" align="center">
                      <table
                        class="es-content-body"
                        width="600"
                        cellspacing="0"
                        cellpadding="0"
                        bgcolor="#ffffff"
                        align="center"
                      >
                        <tbody>
                          <tr>
                            <td
                              class="esd-structure es-p20t es-p20r es-p20l"
                              align="left"
                            >
                              <table
                                width="100%"
                                cellspacing="0"
                                cellpadding="0"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      class="esd-container-frame"
                                      width="560"
                                      valign="top"
                                      align="center"
                                    >
                                      <table
                                        width="100%"
                                        cellspacing="0"
                                        cellpadding="0"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              class="esd-block-button es-p10b"
                                              align="left"
                                            >
                                              <span class="es-button-border"
                                                ><a
                                                  href
                                                  class="es-button"
                                                  target="_blank"
                                                >
                                                  Button
                                                </a></span
                                              >
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                class="es-content esd-footer-popover"
                cellspacing="0"
                cellpadding="0"
                align="center"
              >
                <tbody>
                  <tr>
                    <td class="esd-stripe" align="center">
                      <table
                        class="es-content-body"
                        style="background-color: #ffffff"
                        width="600"
                        cellspacing="0"
                        cellpadding="0"
                        bgcolor="#ffffff"
                        align="center"
                      >
                        <tbody>
                          <tr>
                            <td class="esd-structure" align="left">
                              <table
                                width="100%"
                                cellspacing="0"
                                cellpadding="0"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      class="esd-container-frame"
                                      width="600"
                                      valign="top"
                                      align="center"
                                    >
                                      <table
                                        width="100%"
                                        cellspacing="0"
                                        cellpadding="0"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              class="esd-block-spacer es-p40b es-p40r es-p40l"
                                              align="center"
                                              style="font-size: 0"
                                            >
                                              <table
                                                width="100%"
                                                height="100%"
                                                cellspacing="0"
                                                cellpadding="0"
                                                border="0"
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      style="
                                                        border-bottom: 0px solid
                                                          #efefef;
                                                        background: rgba(
                                                            0,
                                                            0,
                                                            0,
                                                            0
                                                          )
                                                          none repeat scroll 0%
                                                          0%;
                                                        height: 1px;
                                                        width: 100%;
                                                        margin: 0px;
                                                      "
                                                    ></td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td
                              class="esd-structure es-p20t es-p20b es-p20r es-p20l"
                              align="left"
                            >
                              <!--[if mso]><table width="560" cellpadding="0" 
                        cellspacing="0"><tr><td width="270" valign="top"><![endif]-->
                              <table
                                class="es-left"
                                cellspacing="0"
                                cellpadding="0"
                                align="left"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      class="es-m-p20b esd-container-frame"
                                      width="270"
                                      align="left"
                                    >
                                      <table
                                        width="100%"
                                        cellspacing="0"
                                        cellpadding="0"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              class="esd-block-text es-m-txt-c"
                                              align="left"
                                            >
                                              <p style="color: #333333">
                                                Lorem ipsum dolor sit amet,
                                                consectetur adipiscing elit.<br />
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td><td width="20"></td><td width="270" valign="top"><![endif]-->
                              <table
                                class="es-right"
                                cellspacing="0"
                                cellpadding="0"
                                align="right"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      class="esd-container-frame"
                                      width="270"
                                      align="left"
                                    >
                                      <table
                                        width="100%"
                                        cellspacing="0"
                                        cellpadding="0"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              class="esd-block-social es-p5t es-m-txt-c"
                                              align="right"
                                              style="font-size: 0"
                                            >
                                              <table
                                                class="es-table-not-adapt es-social"
                                                cellspacing="0"
                                                cellpadding="0"
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      class="es-p10r"
                                                      valign="top"
                                                      align="center"
                                                    >
                                                      <a target="_blank" href
                                                        ><img
                                                          title="Facebook"
                                                          src="https://hpy.stripocdn.email/content/assets/img/social-icons/logo-black/facebook-logo-black.png"
                                                          alt="Fb"
                                                          width="32"
                                                      /></a>
                                                    </td>
                                                    <td
                                                      class="es-p10r"
                                                      valign="top"
                                                      align="center"
                                                    >
                                                      <a target="_blank" href
                                                        ><img
                                                          title="Twitter"
                                                          src="https://hpy.stripocdn.email/content/assets/img/social-icons/logo-black/twitter-logo-black.png"
                                                          alt="Tw"
                                                          width="32"
                                                      /></a>
                                                    </td>
                                                    <td
                                                      class="es-p10r"
                                                      valign="top"
                                                      align="center"
                                                    >
                                                      <a target="_blank" href
                                                        ><img
                                                          title="Instagram"
                                                          src="https://hpy.stripocdn.email/content/assets/img/social-icons/logo-black/instagram-logo-black.png"
                                                          alt="Inst"
                                                          width="32"
                                                      /></a>
                                                    </td>
                                                    <td
                                                      class="es-p10r"
                                                      valign="top"
                                                      align="center"
                                                    >
                                                      <a target="_blank" href
                                                        ><img
                                                          title="Youtube"
                                                          src="https://hpy.stripocdn.email/content/assets/img/social-icons/logo-black/youtube-logo-black.png"
                                                          alt="Yt"
                                                          width="32"
                                                      /></a>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td></tr></table><![endif]-->
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </body>
</html>`;
