import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface contactProps {
  contact: any;
}

const Contact: FC<contactProps> = ({ contact }) => {
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [hideItem, setHideItem] = useState(false);
  const navigate = useNavigate();
  const deleteContact = async (id: number) => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this contact?'
    );
    if (!confirmDelete) {
      return;
    }
    setLoadingDelete(true);
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const storedJWT = localStorage.getItem('jwt');
    user.email &&
      (await fetch(process.env.REACT_APP_API_URL + '/delete-contact.php', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + storedJWT,
        },
        body: JSON.stringify({
          id: id,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message === 'success') {
            setHideItem(true);
          } else if (data.error) {
            alert(data.error);
            setLoadingDelete(false);
          } else {
            alert('Failed, try again');
            setLoadingDelete(false);
          }
        })
        .catch((error) => {
          // Handle network or server errors
          console.log('error');
          console.log(error);
          alert(error);
          setLoadingDelete(false);
        }));
  };

  return (
    !hideItem && (
      <li
        key={contact.email}
        className="relative flex justify-between px-4 py-5 fade-in group gap-x-6 hover:bg-gray-50 sm:px-6 lg:px-8"
      >
        <div
          className="flex min-w-0 cursor-pointer gap-x-4"
          onClick={() => navigate(`/contacts/${contact.ID}`)}
        >
          <img
            src={`${
              contact.image === 'contact-image-placeholder.jpg'
                ? 'contact-image-placeholder.jpg'
                : 'data:image/jpeg;base64,' + contact.base64
            }`}
            alt={contact.firstname + ' ' + contact.lastname}
            className="flex-none object-cover w-12 h-12 rounded-full bg-gray-50"
          />
          <div className="flex-auto min-w-0">
            <p className="text-sm font-semibold leading-6 text-gray-900">
              <a onClick={() => navigate(`/contacts/${contact.ID}`)}>
                {contact.firstname} {contact.lastname}
              </a>
            </p>
            <p className="flex mt-1 text-xs leading-5 text-gray-500">
              <a
                href={`mailto:${contact.email}`}
                className="relative truncate hover:underline"
              >
                {contact.email}
              </a>
            </p>
          </div>
        </div>
        <div className="flex items-center shrink-0 gap-x-10">
          {loadingDelete ? (
            <svg
              className={` w-6 h-6 animate-spin mr-5`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="#ffffff"
              className={` 
                  p-1 transition-colors hidden group-hover:inline-block  mr-5  bg-opacity-50 rounded-md cursor-pointer w-7 h-7  bg-slate-700 hover:bg-red-700 group-hover:inline-block`}
              onClick={() => deleteContact(contact.ID)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
              />
            </svg>
          )}
          <div className="hidden sm:flex sm:flex-col sm:items-end">
            <p className="text-sm leading-6 text-gray-900">{contact.title}</p>

            <p className="mt-1 text-xs leading-5 text-gray-500">
              {contact.company}
            </p>
          </div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 text-gray-500 transition-colors cursor-pointer hover:text-gray-900"
              onClick={() => navigate(`/contacts/${contact.ID}`)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </div>
        </div>
      </li>
    )
  );
};
export default Contact;
