import React, { useRef, useEffect, useState } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import 'cropperjs/dist/cropper.css';

interface CropperToolProps {
  image: string;
  fileName: string;
  toggleCropper: () => void;
}

const CropperTool: React.FC<CropperToolProps> = ({
  image,
  fileName,
  toggleCropper,
}) => {
  const closeRef = useRef<HTMLDivElement>(null);
  const cropperRef = useRef<ReactCropperElement>(null);

  const [loadingDownload, setLoadingDownload] = useState(false);

  const [selectedCrop, setSelectedCrop] = useState('');
  const [selectedMode, setSelectedMode] = useState('move');
  const onCrop = () => {
    const cropper = cropperRef.current?.cropper;
    console.log(cropper?.getData());
  };

  const downloadImage = () => {
    setLoadingDownload(true);
    fileName = fileName.replace(/\.[^/.]+$/, '');
    const cropper = cropperRef.current?.cropper;
    cropper?.getCroppedCanvas().toBlob(
      (blob) => {
        if (!blob) return;
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName + '.jpg';
        a.click();
        URL.revokeObjectURL(url);
        setLoadingDownload(false);
      },
      'image/jpeg',
      1
    );
  };
  const setAspectRatio = (type: string) => {
    const cropper = cropperRef.current?.cropper;

    let currentType = selectedCrop;
    if (currentType === type) {
      setSelectedCrop('');
      cropper?.setAspectRatio(NaN);
      return;
    }
    setSelectedCrop(type);

    if (type === 'fb') cropper?.setAspectRatio(1.777);
    if (type === 'ig') cropper?.setAspectRatio(1);
    if (type === 'ldn') cropper?.setAspectRatio(1.91);
    if (type === 'pnt') cropper?.setAspectRatio(0.666);
  };
  const toggleMode = (mode: string) => {
    const cropper = cropperRef.current?.cropper;
    mode === 'move' && cropper?.setDragMode('move');
    mode === 'crop' && cropper?.setDragMode('crop');
    setSelectedMode(mode);
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        closeRef.current &&
        !closeRef.current.contains(event.target as Node)
      ) {
        toggleCropper();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [toggleCropper]);

  return (
    <div className="fixed top-0 left-0 z-20 grid content-center w-full h-full bg-black bg-opacity-80">
      <div className="relative w-full p-5 mx-auto md:p-10 lg:p-12 xl:p-20">
        <div ref={closeRef} className="inline-block">
          <div className="relative inline-block ">
            <div className="overflow-hidden lg:max-h-[80vh]">
              <Cropper
                src={image}
                // Cropper.js options
                initialAspectRatio={16 / 9}
                guides={false}
                crop={onCrop}
                ref={cropperRef}
                className="max-h-[80vh] overflow-hidden"
              />
            </div>
            <svg
              onClick={toggleCropper}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              className="absolute w-8 h-8 transition-all cursor-pointer lg:w-12 lg:h-12 fill-white stroke-red-500 -top-3 -right-3 lg:-right-5 lg:-top-5 hover:stroke-white hover:fill-red-500"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          </div>
          <div className="flex flex-wrap items-center justify-center gap-3 mt-5">
            <a
              className={`transition-all inline-block border-2 p-[3px] bg-sky-500 rounded-md cursor-pointer hover:opacity-80
                ${
                  selectedMode === 'move'
                    ? ' border-green-400'
                    : 'border-transparent'
                }`}
              onClick={() => toggleMode('move')}
            >
              <svg
                className="w-5 h-5 text-white"
                fill="currentColor"
                height="16"
                viewBox="0 0 16 16"
                width="16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.646.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 1.707V5.5a.5.5 0 0 1-1 0V1.707L6.354 2.854a.5.5 0 1 1-.708-.708l2-2zM8 10a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 14.293V10.5A.5.5 0 0 1 8 10zM.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2zM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8z"
                  fill-rule="evenodd"
                />
              </svg>
            </a>
            <a
              className={`transition-all border-2 inline-block p-[3px] bg-sky-500 rounded-md cursor-pointer hover:opacity-80
                ${
                  selectedMode === 'crop'
                    ? ' border-green-400'
                    : 'border-transparent'
                }`}
              onClick={() => toggleMode('crop')}
            >
              <svg
                className="w-5 h-5 text-white fill-white"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path d="M6 1V18H23V20H20V23H18V20H4V6H1V4H4V1H6Z" />
                <path d="M8 4H20V16H18V6H8V4Z" />
              </svg>
            </a>

            <a
              className={`transition-all border-2 ml-5 rounded-full cursor-pointer hover:opacity-80
                ${
                  selectedCrop === 'pnt'
                    ? ' border-green-400'
                    : 'border-transparent'
                }`}
              onClick={() => setAspectRatio('pnt')}
            >
              <img
                src="./pin-icon.svg"
                alt="Pinterest"
                className="w-6 h-6 bg-white rounded-full"
              />
            </a>
            <a
              className={`transition-all border-2 rounded-md cursor-pointer hover:opacity-80
              ${
                selectedCrop === 'ldn'
                  ? ' border-green-400'
                  : 'border-transparent'
              }`}
              onClick={() => setAspectRatio('ldn')}
            >
              <img
                src="./ldn-icon.svg"
                alt="LinkedIn"
                className="w-6 h-6 bg-white rounded-md"
              />
            </a>
            <a
              className={`transition-all border-2 inline-block rounded-[8px] cursor-pointer hover:opacity-80
              ${
                selectedCrop === 'ig'
                  ? ' border-green-400'
                  : 'border-transparent'
              }`}
              onClick={() => setAspectRatio('ig')}
            >
              <img
                src="./ig-icon.svg"
                alt="Instagram"
                className="w-6 h-6 bg-white rounded-[7px]"
              />
            </a>
            <a
              className={`transition-all border-2 inline-block rounded-md cursor-pointer hover:opacity-80
              ${
                selectedCrop === 'fb'
                  ? ' border-green-400'
                  : 'border-transparent'
              }`}
              onClick={() => setAspectRatio('fb')}
            >
              <img
                src="./fb-icon.svg"
                alt="Facebook"
                className="w-6 h-6 bg-white rounded-md"
              />
            </a>
            <a
              className="flex items-center gap-2 ml-5 cursor-pointer group"
              onClick={downloadImage}
            >
              {loadingDownload ? (
                <svg
                  className={` w-5 h-5 md:w-7 md:h-7 text-white animate-spin`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="#ffffff"
                  className={`p-[3px]  h-7 w-7 rounded-md
               inline-block transition-colors  bg-sky-500 group-hover:bg-opacity-80	`}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                  />
                </svg>
              )}
              <span className="hidden text-sm text-white md:block group-hover:text-sky-600 ">
                Download
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CropperTool;
