import type { FC } from 'react';
import { useState, useEffect } from 'react';
import CropperTool from './cropper';
interface fileitemProps {
  view: string;
  file: any;
  groups: Array<any>;
}

const FileItem: FC<fileitemProps> = ({ view, file, groups }) => {
  console.log(file);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [loadingLowResDownload, setLoadingLowResDownload] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [hideItem, setHideItem] = useState(false);
  const [groupColours, setGroupColours] = useState<Array<any>>([]);
  const [showCropTool, setShowCropTool] = useState(false);
  const [loadingCropTool, setLoadingCropTool] = useState(false);
  const [cropImage, setCropImage] = useState<any>('');
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('user') || '{}')
  );
  const [visibility, setVisibility] = useState<Array<number>>([0, 1]);

  //check file extension
  const fileExtension = file.file.split('.').pop();

  const getPlaceholder = (fileExtension: string): string => {
    const extensionToPlaceholder: { [key: string]: string } = {
      mp4: '/placeholder-video.svg',
      mov: '/placeholder-video.svg',
      avi: '/placeholder-video.svg',
      wmv: '/placeholder-video.svg',
      doc: '/placeholder-word.svg',
      docx: '/placeholder-word.svg',
      xls: '/placeholder-excel.svg',
      xlsx: '/placeholder-excel.svg',
      svg: '/placeholder-svg.svg',
      pdf: '/placeholder-pdf.svg',
      eps: '/placeholder-eps.svg',
      ai: '/placeholder-illustrator.svg',
      psd: '/placeholder-photoshop.svg',
      indd: '/placeholder-indesign.svg',
    };

    return extensionToPlaceholder[fileExtension] || 'placeholder-document.svg';
  };

  let placeholder = getPlaceholder(fileExtension);

  const downloadFile = async (id: number, filename: string) => {
    setLoadingDownload(true);
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const storedJWT = localStorage.getItem('jwt');
    user.email &&
      (await fetch(process.env.REACT_APP_API_URL + '/download-file.php', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + storedJWT,
        },
        body: JSON.stringify({
          id: id,
          email: user.email,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              'Network response was not ok ' + response.statusText
            );
          }
          return response.blob();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          // the filename you want
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          setLoadingDownload(false);
        })
        .catch((error) => {
          // Handle network or server errors
          console.log('error');
          alert(error);
          setLoadingDownload(false);
        }));
  };
  const downloadLowResFile = async (id: number, filename: string) => {
    setLoadingLowResDownload(true);
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const storedJWT = localStorage.getItem('jwt');
    user.email &&
      (await fetch(
        process.env.REACT_APP_API_URL + '/download-lowres-file.php',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + storedJWT,
          },
          body: JSON.stringify({
            id: id,
            email: user.email,
          }),
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              'Network response was not ok ' + response.statusText
            );
          }
          return response.blob();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          // the filename you want
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          setLoadingLowResDownload(false);
        })
        .catch((error) => {
          // Handle network or server errors
          console.log('error');
          alert(error);
          setLoadingLowResDownload(false);
        }));
  };
  const deleteFile = async (id: number) => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this file?'
    );
    if (!confirmDelete) {
      return;
    }
    setLoadingDelete(true);
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const storedJWT = localStorage.getItem('jwt');
    user.email &&
      (await fetch(process.env.REACT_APP_API_URL + '/move-to-trash.php', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + storedJWT,
        },
        body: JSON.stringify({
          id: id,
          email: user.email,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message === 'success') {
            setHideItem(true);
          } else if (data.error) {
            alert(data.error);
            setLoadingDelete(false);
          }
        })
        .catch((error) => {
          // Handle network or server errors
          console.log('error');
          console.log(error);
          alert(error);
          setLoadingDelete(false);
        }));
  };
  const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };
  //create a readable date from file.date
  const date = new Date(file.date);
  const readableDate = date.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
  const getGroupColour = async (groupID: number) => {
    const storedJWT = localStorage.getItem('jwt');

    await fetch(process.env.REACT_APP_API_URL + '/get-group-colour.php', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + storedJWT,
      },
      body: JSON.stringify({
        id: groupID,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setGroupColours((groupColours) => [...groupColours, data]);
      })
      .catch((error) => {
        // Handle network or server errors
        console.log('error');
        console.log(error);
        alert(error);
      });
  };
  const openCropTool = async (id: number) => {
    setLoadingCropTool(true);
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const storedJWT = localStorage.getItem('jwt');
    try {
      if (user.email) {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/get-cropper-image.php`,
          {
            method: 'POST',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${storedJWT}`,
            },
            body: JSON.stringify({
              id: id,
              email: user.email,
            }),
          }
        );

        const data = await response.json();

        if (data.base64) {
          setCropImage(data.base64);
          setShowCropTool(true);
        } else {
          alert('No image found');
        }
        setLoadingCropTool(false);
      }
    } catch (error) {
      console.error('Error:', error);
      setLoadingCropTool(false);
      return null;
    }
  };
  const closeCropTool = () => {
    setShowCropTool(false);
  };
  useEffect(() => {
    const vis_array = JSON.parse(file.access_level);
    setVisibility(vis_array);
    vis_array.map((groupID: number) => {
      if (groupID !== 0 && groupID !== 1) {
        getGroupColour(groupID);
      }
    });
  }, []);

  const roles = JSON.parse(user.groups || '[]');

  return (
    !hideItem && (
      <>
        <div
          className={` ${
            view === 'list'
              ? 'w-full col-span-4 mb-5 md:mb-2 h-auto xl:flex xl:hover:bg-slate-100 transition justify-between max-w-full '
              : 'relative fade-in'
          }
             ${view === 'small' && 'col-span-1 lg:col-span-2 xl:col-span-1'} 
            ${view === 'medium' && 'col-span-2 '}
            ${view === 'large' && 'col-span-4 '}`}
        >
          <div
            className={`
        ${
          view === 'list'
            ? 'px-2  md:mb-2 py-3 w-full md:py-5 xl:py-0 border-b xl:border-none h-auto xl:flex xl:hover:bg-slate-100 transition justify-between max-w-full overflow-hidden  '
            : 'border-gray-100 border rounded-md grid content-end items-end relative p-3 h-full'
        }
          relative overflow-hidden group`}
            key={file.ID}
          >
            <div
              className={`${
                view !== 'list'
                  ? 'mb-2 grid content-end'
                  : 'flex md:p-1 mb-4 md:mb-0'
              } md:rounded-md 
            ${view === 'small' && 'h-[150px] '} 
            ${view === 'medium' && 'h-[290px] lg:h-[350px]'}
            ${view === 'large' && 'h-[180px] md:h-[500px]'}`}
            >
              {file.base64 ? (
                <img
                  src={`data:image/jpeg;base64,${file.base64}`}
                  alt={file.file}
                  className={`${
                    view === 'list'
                      ? 'h-10 w-10 inline-block object-contain mb-1 md:mb-0 mr-3 md:mr-5 object-left'
                      : 'w-full h-full  absolute top-0 left-0 object-cover'
                  } 
        `}
                />
              ) : (
                <img
                  src={placeholder}
                  alt={file.file}
                  className={`${
                    view === 'list'
                      ? 'h-10 w-10 inline-block mr-3 md:mr-5 object-contain object-left'
                      : 'w-full h-full object-scale-down absolute top-0 left-0 '
                  }  
    `}
                />
              )}
              {view !== 'list' && (
                <div className="absolute top-0 left-0 w-full h-full transition-opacity opacity-50 bg-gradient-to-t via-transparent from-black group-hover:opacity-0" />
              )}
              {visibility.length > 1 && view !== 'list' && (
                <div className="relative flex-wrap content-center w-full gap-1 mr-5">
                  {(roles.includes(0) || roles.includes(1)) && (
                    <div className={`relative mb-2 flex gap-1`}>
                      {groupColours.map((item) => (
                        <div
                          key={`group-colour-${item.ID}`}
                          className={`w-3 h-3 border border-white rounded-full  relative`}
                          style={{ backgroundColor: item.colour }}
                        ></div>
                      ))}
                    </div>
                  )}
                </div>
              )}
              <div
                className={`${
                  view === 'list' && ' max-w-[400px]'
                } grid items-center content-center w-full`}
              >
                <p
                  title={file.file}
                  className={`relative ${
                    view == 'list'
                      ? 'text-default pr-12 max-h-[42px]  md:max-h-auto  '
                      : 'text-white '
                  }
          ${view == 'small' ? 'text-xs' : 'text-xs md:text-sm'}
           pr-5 overflow-hidden filename-ellipsis `}
                >
                  {file.file}
                </p>
                {visibility.length > 1 &&
                  (roles.includes(0) || roles.includes(1)) &&
                  view === 'list' && (
                    <div className={`relative mt-1 flex gap-1`}>
                      {groupColours.map((item) => (
                        <div
                          key={item.ID}
                          className={`w-2 h-2 rounded-full  relative`}
                          style={{ backgroundColor: item.colour }}
                        ></div>
                      ))}
                    </div>
                  )}
              </div>
            </div>

            <div
              className={`${
                view !== 'list'
                  ? 'flex justify-between flex-wrap'
                  : 'flex items-center align-right '
              } `}
            >
              {(roles.includes(0) || roles.includes(1)) && (
                <>
                  {loadingDelete ? (
                    <svg
                      className={` ${
                        view !== 'list'
                          ? 'absolute left-0 top-0'
                          : 'hidden xl:block'
                      } block absolute  text-white h-5 w-5 md:w-8 md:h-8 animate-spin`}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="#ffffff"
                      className={` ${
                        view !== 'list'
                          ? 'absolute left-0 top-0  p-1.5  h-7 w-7 md:w-8 md:h-8 xl:hidden rounded-br-md xl:group-hover:inline-block'
                          : 'hidden rounded-md h-6 w-6 md:w-7 p-1  md:h-7 xl:group-hover:inline-block mr-5'
                      }   transition-colors bg-opacity-50  overflow-hidden cursor-pointer   bg-slate-700 hover:bg-red-700 `}
                      onClick={() => deleteFile(file.ID)}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                  )}
                </>
              )}
              {view === 'list' && (
                <div className="relative flex flex-wrap items-center gap-5 mr-5">
                  {fileExtension === 'jpg' ||
                  fileExtension === 'jpeg' ||
                  fileExtension === 'png' ? (
                    <>
                      {loadingCropTool ? (
                        <svg
                          className={` w-5 h-5  text-accent animate-spin`}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      ) : (
                        <a
                          className="flex items-center gap-2 cursor-pointer "
                          onClick={() => openCropTool(file.ID)}
                        >
                          <svg
                            className={`p-[3px]  h-5 w-5 rounded-md
                    inline-block transition-colors bg-opacity-50 fill-white bg-slate-700 hover:bg-sky-600	`}
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path d="M6 1V18H23V20H20V23H18V20H4V6H1V4H4V1H6Z" />
                            <path d="M8 4H20V16H18V6H8V4Z" />
                          </svg>
                        </a>
                      )}
                      <a
                        className="flex items-center gap-2 cursor-pointer group/download"
                        onClick={() => downloadLowResFile(file.ID, file.file)}
                      >
                        {loadingLowResDownload ? (
                          <svg
                            className={` w-3 h-3 md:w-5 md:h-5 text-accent animate-spin`}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="#ffffff"
                            className={`p-[3px]  h-5 w-5 rounded-md
               inline-block transition-colors bg-opacity-50 cursor-pointer  bg-slate-700 group-hover/download:bg-sky-600`}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                            />
                          </svg>
                        )}
                        <span className="text-xs group-hover/download:text-sky-600">
                          Low-res
                        </span>
                      </a>
                      <a
                        className="flex items-center gap-2 cursor-pointer group/download"
                        onClick={() => downloadFile(file.ID, file.file)}
                      >
                        {loadingDownload ? (
                          <svg
                            className={` w-5 h-5 md:w-5 md:h-5 text-accent animate-spin`}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="#ffffff"
                            className={`p-[3px]  h-5 w-5 rounded-md
               inline-block transition-colors bg-opacity-50   bg-slate-700 group-hover/download:bg-sky-600	`}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                            />
                          </svg>
                        )}
                        <span className="text-xs group-hover/download:text-sky-600 ">
                          High-res
                        </span>
                      </a>
                    </>
                  ) : (
                    <a
                      className="flex items-center gap-2 cursor-pointer group/download"
                      onClick={() => downloadFile(file.ID, file.file)}
                    >
                      {loadingDownload ? (
                        <svg
                          className={` w-5 h-5 md:w-6 md:h-6 text-accent animate-spin`}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="#ffffff"
                          className={`p-[2px]  h-5 w-5 rounded-md inline-block transition-colors bg-opacity-50 bg-slate-700 group-hover/download:bg-sky-600	`}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                          />
                        </svg>
                      )}
                      <span className="text-xs group-hover/download:text-sky-600 ">
                        Download
                      </span>
                    </a>
                  )}
                </div>
              )}
              <p
                className={`relative ${
                  view === 'list'
                    ? 'hidden md:block text-gray-500 pr-12 text-xs'
                    : ' text-white'
                }
          ${view == 'small' ? 'text-xs' : 'text-sm'}
           pr-5`}
              >
                {readableDate}
              </p>
              <p
                className={`relative ${
                  view == 'list'
                    ? 'hidden text-xs xl:text-sm  md:block text-gray-500 pr-5'
                    : 'text-white '
                }
          ${view == 'small' ? 'text-xs' : 'text-sm'}
         `}
              >
                {formatBytes(file.size)}
              </p>
              {view === 'list' && (roles.includes(0) || roles.includes(1)) && (
                <>
                  {loadingDelete ? (
                    <svg
                      className={`xl:hidden  h-6 w-6 md:w-7 md:h-7 animate-spin mr-5`}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="#ffffff"
                      className={`xl:hidden p-1 transition-colors mr-5  bg-opacity-50 rounded-md cursor-pointer h-6 w-6 md:w-7 md:h-7   bg-slate-700 hover:bg-red-700 `}
                      onClick={() => deleteFile(file.ID)}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                  )}
                </>
              )}
            </div>
          </div>
          {view !== 'list' && (
            <div className="relative flex flex-wrap justify-between mt-3">
              {fileExtension === 'jpg' ||
              fileExtension === 'jpeg' ||
              fileExtension === 'png' ? (
                <>
                  <div className="flex flex-wrap gap-5">
                    <a
                      className="flex items-center gap-2 cursor-pointer group"
                      onClick={() => downloadLowResFile(file.ID, file.file)}
                    >
                      {loadingLowResDownload ? (
                        <svg
                          className={` w-3 h-3 md:w-6 md:h-6 text-accent animate-spin`}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="#ffffff"
                          className={`p-[3px]  h-6 w-6 rounded-md
               inline-block transition-colors bg-opacity-50 cursor-pointer  bg-slate-700 group-hover:bg-sky-600`}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                          />
                        </svg>
                      )}
                      <span className="text-sm lg:text-xs xl:text-sm group-hover:text-sky-600">
                        Low-res
                      </span>
                    </a>
                    <a
                      className="flex items-center gap-2 cursor-pointer group"
                      onClick={() => downloadFile(file.ID, file.file)}
                    >
                      {loadingDownload ? (
                        <svg
                          className={` w-5 h-5 md:w-6 md:h-6 text-accent animate-spin`}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="#ffffff"
                          className={`p-[3px]  h-6 w-6 rounded-md
               inline-block transition-colors bg-opacity-50   bg-slate-700 group-hover:bg-sky-600	`}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                          />
                        </svg>
                      )}
                      <span className="text-sm lg:text-xs xl:text-sm group-hover:text-sky-600 ">
                        High-res
                      </span>
                    </a>
                  </div>
                  <div>
                    {loadingCropTool ? (
                      <svg
                        className={` w-5 h-5 md:w-6 md:h-6 text-accent animate-spin`}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    ) : (
                      <a
                        className="flex items-center gap-2 cursor-pointer group"
                        onClick={() => openCropTool(file.ID)}
                      >
                        <svg
                          className={`p-[4px]  h-6 w-6 rounded-md
                    inline-block transition-colors bg-opacity-50 fill-white bg-slate-700 group-hover:bg-sky-600	`}
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path d="M6 1V18H23V20H20V23H18V20H4V6H1V4H4V1H6Z" />
                          <path d="M8 4H20V16H18V6H8V4Z" />
                        </svg>
                      </a>
                    )}
                  </div>
                </>
              ) : (
                <a
                  className="flex items-center gap-2 cursor-pointer group"
                  onClick={() => downloadFile(file.ID, file.file)}
                >
                  {loadingDownload ? (
                    <svg
                      className={` w-5 h-5 md:w-6 md:h-6 text-accent animate-spin`}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="#ffffff"
                      className={`p-[3px]  h-6 w-6 rounded-md
             inline-block transition-colors bg-opacity-50   bg-slate-700 group-hover:bg-sky-600	`}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                      />
                    </svg>
                  )}
                  <span className="text-sm group-hover:text-sky-600 ">
                    Download
                  </span>
                </a>
              )}
            </div>
          )}
        </div>
        {showCropTool && (
          <CropperTool
            fileName={file.file}
            toggleCropper={closeCropTool}
            image={`data:image/jpeg;base64,${cropImage}`}
          />
        )}
      </>
    )
  );
};
export default FileItem;
