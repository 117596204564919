import { useEffect, useState, FC } from 'react';

import Dropdown from './dropdown';

interface mailStatsProps {
  toggleActiveTab: () => void;
}
const FileStats: FC<mailStatsProps> = ({ toggleActiveTab }) => {
  const [loading, setLoading] = useState(false);
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [files, setFiles] = useState<any>([]);

  const toggleTab = () => {
    toggleActiveTab();
  };
  const searchFiles = async () => {
    setLoading(true);
    setErrorMessage('');
    console.log('search files', dateFrom, dateTo);
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const storedJWT = localStorage.getItem('jwt');
    //check if dateTo and dateFrom are not empty
    const fromDate = new Date(dateFrom);
    const toDate = new Date(dateTo);

    if (dateFrom && dateTo && fromDate <= toDate) {
      user.email &&
        (await fetch(process.env.REACT_APP_API_URL + '/get-file-stats.php', {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + storedJWT,
          },
          body: JSON.stringify({
            date_from: dateFrom,
            date_to: dateTo,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            setFiles(data);
            setLoading(false);
          })
          .catch((error) => {
            // Handle network or server errors
            console.log('error');
            console.log(error);
            setLoading(false);
          }));
    } else {
      setErrorMessage('Please set the correct dates');
      setLoading(false);
    }
  };
  const getInitialStats = async () => {
    setLoading(true);
    setErrorMessage('');

    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const storedJWT = localStorage.getItem('jwt');
    //check if dateTo and dateFrom are not empty
    const today = new Date();
    const lastWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);

    // Setting the dates
    const dateFromValue = lastWeek.toISOString().split('T')[0];
    const dateToValue = today.toISOString().split('T')[0];

    setDateFrom(dateFromValue);
    setDateTo(dateToValue);

    await fetch(process.env.REACT_APP_API_URL + '/get-file-stats.php', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + storedJWT,
      },
      body: JSON.stringify({
        date_from: dateFromValue,
        date_to: dateToValue,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setFiles(data);
        setLoading(false);
      })
      .catch((error) => {
        // Handle network or server errors
        console.log('error');
        console.log(error);
        setLoading(false);
      });
  };
  //set the initial dates to one week from now and search for files
  useEffect(() => {
    getInitialStats();
  }, []); // Empty dependency array ensures this runs only once on mount

  return (
    <div className="relative max-w-full fade-in">
      <div className="flex gap-10">
        <button
          onClick={toggleTab}
          className="flex outline-none text-default hover:text-slate-700"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
            ></path>
          </svg>
          <span className="ml-1 text-sm">back</span>
        </button>
        <h3 className="text-sm font-semibold leading-6 text-gray-700 md:text-base">
          File statistics
        </h3>
      </div>
      <div className="flex flex-wrap items-end gap-4 mt-10 mb-5">
        <div id="date_from">
          <label className="text-sm font-semibold leading-6 text-gray-700">
            From
          </label>
          <input
            type="date"
            className="w-full px-2 py-1 mt-1 text-sm border border-gray-300 rounded-md"
            value={dateFrom}
            onChange={(e) => setDateFrom(e.target.value)}
          />
        </div>
        <div id="date_to">
          <label className="text-sm font-semibold leading-6 text-gray-700">
            To
          </label>
          <input
            type="date"
            className="w-full px-2 py-1 mt-1 text-sm border border-gray-300 rounded-md"
            value={dateTo}
            onChange={(e) => setDateTo(e.target.value)}
          />
        </div>
        <div>
          {loading ? (
            <svg
              className="inline-block w-5 h-5 mb-1.5 text-accent animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            <button
              className="px-3 py-2 text-xs font-bold rounded-lg bg-slate-300 hover:bg-slate-200"
              onClick={searchFiles}
            >
              SEARCH
            </button>
          )}
        </div>
      </div>
      <div>
        <p className="text-sm text-red-600">{errorMessage}</p>
      </div>
      {files.length > 0 && (
        <div className="relative flow-root p-3 mt-10 overflow-x-scroll md:mt-20">
          <div className="overflow-x-auto ">
            <div className="inline-block min-w-full py-2 align-middle max-h-[400px] md:max-h-[600px] overflow-y-scroll ">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th
                      scope="col"
                      className="p-1.5 md:py-3.5 md:pl-4  md:pr-3 text-left text-xs md:text-sm font-semibold text-gray-900 "
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="p-1.5 md:py-3.5 md:pl-4  md:pr-3 text-left text-xs md:text-sm font-semibold text-gray-900 "
                    >
                      Path
                    </th>
                    <th
                      scope="col"
                      className="p-1.5 md:py-3.5 md:pl-4  md:pr-3 text-left text-xs md:text-sm font-semibold text-gray-900 "
                    >
                      Downloads
                    </th>
                  </tr>
                </thead>
                <tbody className="relative bg-white divide-y divide-gray-200 ">
                  {files.map((file: any) => (
                    <tr key={file.file_id}>
                      <td className="p-1.5 min-w-[60px] md:py-4 md:pl-4 md:pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-0">
                        {file.base64 ? (
                          <img
                            src={`data:image/jpeg;base64,${file.base64}`}
                            alt={`Suzy`}
                            className="object-cover w-12 h-12 rounded-md bg-gray-50"
                          />
                        ) : (
                          <img
                            src="/placeholder-document.svg"
                            alt="Suzy"
                            className="w-12 h-12"
                          />
                        )}
                      </td>
                      <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 truncate whitespace-nowrap sm:pl-0">
                        {file.file}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                        {file.path}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                        {file.count}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default FileStats;
