import React, { useEffect, useRef, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import FileItem from './fileitem';
import FolderItem from './folderitem';
import AppContext from '../utils/appContext';

interface FileBrowserProps {
  folderPath: string;
  changeFolderPath: (path: string) => void;
  refreshCounter: number;
  groups: Array<any>;
  toggleActiveTab: () => void;
}

const FileBrowser: React.FC<FileBrowserProps> = ({
  folderPath,
  changeFolderPath,
  refreshCounter,
  groups,
  toggleActiveTab,
}) => {
  const viewList = ['list', 'small', 'medium', 'large'];
  const [files, setFiles] = useState<Array<any>>([]);
  const [totalFiles, setTotalFiles] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [folders, setFolders] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);

  const [showContent, toggleContent] = useState(1);
  const [view, setView] = useState(viewList[1]);

  const [folderError, setFolderError] = useState('');
  const [folderSuccess, setFolderSuccess] = useState('');
  const [pathMenu, setPathMenu] = useState<Array<string>>([]);

  const context = useContext(AppContext);
  const navigate = useNavigate();
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('user') || '{}')
  );

  const refresh = () => {
    getFolders().then(() => getInitialFiles());
    setFolderSuccess('');
  };
  const fetchData = async () => {
    try {
      await getFolders();
      await getInitialFiles();
      await getTotalFiles();
      updatePathMenu(folderPath);
      setFolderSuccess('');
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getInitialFiles = async () => {
    setLoading(true);
    setFolderError('');

    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const storedJWT = localStorage.getItem('jwt');
    let thumb = view === 'list' ? 'small' : view;

    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/get-folder-content.php',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + storedJWT,
          },
          body: JSON.stringify({
            email: user.email,
            path: folderPath,
            size: thumb,
            page: 0,
          }),
        }
      );

      const data = await response.json();
      setFiles(data);
      setLoading(false);
      toggleContent(1);
      if (data === 'Error decoding token: Expired token') {
        logout();
      }
    } catch (error) {
      console.error('Error fetching initial files:', error);
      setLoading(false);
      toggleContent(1);
    }
  };

  const getFiles = async () => {
    setLoading(true);
    setFolderError('');

    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const storedJWT = localStorage.getItem('jwt');
    let thumb = view === 'list' ? 'small' : view;

    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/get-folder-content.php',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + storedJWT,
          },
          body: JSON.stringify({
            email: user.email,
            path: folderPath,
            size: thumb,
            page: page,
          }),
        }
      );

      const data = await response.json();
      setFiles([...files, ...data]);
      setLoading(false);
      toggleContent(1);
      if (data === 'Error decoding token: Expired token') {
        logout();
      }
    } catch (error) {
      console.error('Error fetching files:', error);
      setLoading(false);
      toggleContent(1);
    }
  };

  const getTotalFiles = async () => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const storedJWT = localStorage.getItem('jwt');

    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/get-total-folder-content.php',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + storedJWT,
          },
          body: JSON.stringify({
            email: user.email,
            path: folderPath,
          }),
        }
      );

      const data = await response.json();
      setTotalFiles(data.total);

      if (data === 'Error decoding token: Expired token') {
        logout();
      }
    } catch (error) {
      console.error('Error fetching total files:', error);
      setLoading(false);
    }
  };

  const getFolders = async () => {
    setLoading(true);
    setFolderError('');
    let thumb = view === 'list' || view === 'small' ? 'medium' : view;

    const storedJWT = localStorage.getItem('jwt');

    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/get-folders.php',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + storedJWT,
          },
          body: JSON.stringify({
            path: folderPath,
            size: thumb,
          }),
        }
      );

      const data = await response.json();

      setFolders(data);
      setLoading(false);

      if (data.error) {
        setFolderError(data.error);
      }
    } catch (error) {
      console.error('Error fetching folders:', error);
      setLoading(false);
    }
  };

  const toggleView = () => {
    setView(viewList[(viewList.indexOf(view) + 1) % viewList.length]);
  };

  const sendUpFolderPath = (path: string) => {
    changeFolderPath(path);
  };

  const goToFolder = (index: number) => {
    let newPath = '';
    let pathArray = pathMenu.slice(0, index + 1);
    newPath = '/' + pathArray.join('/');
    sendUpFolderPath(newPath);
  };

  const updatePathMenu = (folderPath: string) => {
    let pathArray: string[] = [];
    if (folderPath !== '/') {
      let newArray = folderPath.split('/');
      newArray.shift(); // remove the first empty item
      pathArray = newArray;
    }
    setPathMenu(pathArray);
  };

  const logout = () => {
    localStorage.clear();
    context?.updateLoginStatus(false);
    navigate('/login');
  };
  const toggleTab = () => {
    toggleActiveTab();
  };

  useEffect(() => {
    getFolders().then(() => getInitialFiles());
    setFolderSuccess('');
  }, [view, refreshCounter]);

  useEffect(() => {
    setPage(0);
    fetchData();
  }, [folderPath]);

  useEffect(() => {
    const fetchData = async () => {
      await getTotalFiles();
    };

    fetchData();

    const div = scrollRef.current!;
    const handleScroll = () => {
      if (div) {
        const isAtBottom =
          div.scrollTop + div.clientHeight >= div.scrollHeight - 1;

        if (isAtBottom) {
          if (page * 20 < totalFiles) {
            setPage((page) => page + 1);
          }
        }
      }
    };

    if (div) {
      div.addEventListener('scroll', handleScroll);

      return () => {
        div.removeEventListener('scroll', handleScroll);
      };
    }
  }, [scrollRef.current, page, totalFiles]);

  useEffect(() => {
    if (page !== 0 && page * 20 < totalFiles) {
      getFiles();
    }
  }, [page]);

  useEffect(() => {
    // If screen width is less than 1000px, set view to 'list'
    if (window.innerWidth < 1000) {
      setView('list');
    }
  }, []);
  const roles = JSON.parse(user.groups || '[]');

  return (
    <div
      className={` min-h-[300px] p-3 lg:py-10 md:pl-5 lg:pl-10 xl:pl-16 fade-in`}
    >
      <div className="relative flex mb-3 md:mb-0 md:min-h-[45px] items-center justify-between pr-2 md:pr-7">
        <div className="flex flex-wrap gap-1 px-2">
          <button
            className="flex items-center gap-1 group"
            onClick={() => sendUpFolderPath('/')}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1}
              className="w-6 h-auto transition stroke-default group-hover:fill-slate-300 group-hover:stroke-slate-300"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
              />
            </svg>
            <span className="group-hover:text-slate-300">/</span>
          </button>
          {pathMenu.length > 0 &&
            pathMenu.map((path, index) => (
              <button
                className="flex items-center gap-1 group"
                key={path + index}
                onClick={() => goToFolder(index)}
              >
                <span className="text-xs transition-all md:text-base group-hover:text-slate-400">
                  {path}
                </span>
                <span className="group-hover:text-slate-300">/</span>
              </button>
            ))}
          {loading && (
            <svg
              className="inline-block mt-0.5 w-5 h-5 text-accent animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
        </div>
        <div className="flex justify-end gap-5">
          {(roles.includes(0) || roles.includes(1)) && (
            <div>
              <button
                className="px-3 py-1 m-1 text-xs text-white transition-all bg-indigo-600 rounded-full shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-indigo-400 control-button"
                onClick={toggleTab}
              >
                File stats
              </button>
            </div>
          )}
          {(view === 'list' || view === 'small' || view === 'medium') && (
            <button
              onClick={() => setView('large')}
              className={`min-h-[26px] content-center grid  md:hidden gap-0.5 group grid-cols-3`}
            >
              <div
                className={`rounded-xs bg-slate-400 group-hover:bg-slate-300 w-2 h-1 col-span-1
            `}
              ></div>
              <div
                className={` rounded-xs bg-slate-400 group-hover:bg-slate-300 w-4 h-1 col-span-2`}
              ></div>

              <div
                className={`w-2 h-1 col-span-1
                 rounded-xs bg-slate-400 group-hover:bg-slate-300`}
              ></div>
              <div
                className={`w-4 h-1 col-span-2 rounded-xs bg-slate-400 group-hover:bg-slate-300`}
              ></div>
            </button>
          )}
          {view === 'large' && (
            <button
              onClick={() => setView('list')}
              className={`min-h-[26px] grid md:hidden content-center gap-0.5 group grid-cols-1`}
            >
              <div
                className={`rounded-xs bg-slate-400 group-hover:bg-slate-300 w-4 h-3
            `}
              ></div>
              <div
                className={` rounded-xs bg-slate-400 group-hover:bg-slate-300 w-4 h-3
            `}
              ></div>
            </button>
          )}
          <button
            onClick={toggleView}
            className={`min-h-[26px] hidden md:grid content-center gap-0.5 group 
          ${view === 'list' && 'grid-cols-3'} 
          ${view === 'small' && 'grid-cols-3'} 
          ${view === 'medium' && 'grid-cols-2'} 
          ${view === 'large' && 'grid-cols-1'}`}
          >
            <div
              className={`rounded-xs bg-slate-400 group-hover:bg-slate-300
            ${view === 'list' && 'w-2 h-1 col-span-1'} 
            ${view === 'small' && 'w-2 h-2'} 
            ${view === 'medium' && 'w-3 h-3'} 
            ${view === 'large' && 'w-4 h-3'} 
            `}
            ></div>
            <div
              className={` rounded-xs bg-slate-400 group-hover:bg-slate-300
            ${view === 'list' && 'w-4 h-1 col-span-2'} 
            ${view === 'small' && 'w-2 h-2'} 
            ${view === 'medium' && 'w-3 h-3'} 
            ${view === 'large' && 'w-4 h-3'} 
            `}
            ></div>
            {view !== 'large' && (
              <>
                <div
                  className={` rounded-xs bg-slate-400 group-hover:bg-slate-300
            ${view === 'list' && 'w-2 h-1 col-span-1'} 
            ${view === 'small' && 'w-2 h-2'} 
            ${view === 'medium' && 'w-3 h-3'} 
            `}
                ></div>
                <div
                  className={` rounded-xs bg-slate-400 group-hover:bg-slate-300
           ${view === 'list' && 'w-4 h-1 col-span-2'}           
            ${view === 'small' && 'w-2 h-2'} 
            ${view === 'medium' && 'w-3 h-3'} 
            `}
                ></div>
              </>
            )}

            {(view === 'small' || view === 'list') && (
              <>
                <div
                  className={`
                ${view === 'list' ? 'w-2 h-1 col-span-1' : 'w-2 h-2'} 
              
                 rounded-xs bg-slate-400 group-hover:bg-slate-300`}
                ></div>
                <div
                  className={`
                ${view === 'list' ? 'w-4 h-1 col-span-2' : 'w-2 h-2'} 
               rounded-xs bg-slate-400 group-hover:bg-slate-300`}
                ></div>
              </>
            )}
          </button>
          <p className="absolute text-sm italic text-red-700 -bottom-5">
            {folderError}
          </p>
          <p className="absolute text-sm italic text-green-600 -bottom-5">
            {folderSuccess}
          </p>
        </div>
      </div>

      <div
        ref={scrollRef}
        className={`${
          view === 'list' ? 'max-h-[60vh] ' : 'max-h-screen'
        }  overflow-y-scroll border-b relative border-slate-100 pr-2 md:pr-5 `}
      >
        {Array.isArray(folders) && folders.length > 0 && (
          <div
            className={`${view === 'list' ? 'gap-1 my-5 ' : 'gap-4 mb-10 mt-5'} 
             
              grid items-start grid-cols-4`}
          >
            {folders.map((folder: any) => (
              <FolderItem
                refresh={refresh}
                key={`folder-${folder.ID}`}
                view={view}
                folder={folder}
                selectFolder={sendUpFolderPath}
                groups={groups}
              />
            ))}
          </div>
        )}

        <div
          className={`${
            view === 'list' ? 'gap-1 my-5 ' : 'mb-10 mt-5'
          } grid items-start grid-cols-4
          ${view === 'small' && 'gap-4 '} 
          ${view === 'medium' && 'gap-5 '} 
          ${view === 'large' && 'gap-7 '} 
        
          `}
        >
          {Array.isArray(files) &&
            files.length > 0 &&
            files.map((item: any) => (
              <FileItem
                key={`file-${item.ID}`}
                view={view}
                file={item}
                groups={groups}
              />
            ))}
        </div>
      </div>
    </div>
  );
};
export default FileBrowser;
