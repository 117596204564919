import { useState, FC, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContext from '../utils/appContext';

interface sendmailProps {
  message: string;
  closeModal: () => void;
}

const SendMail: FC<sendmailProps> = ({ closeModal, message }) => {
  const [loading, setLoading] = useState(false);
  const [contactLists, setContactLists] = useState<Array<any>>([]);
  const [pressLists, setPressLists] = useState<Array<any>>([]);
  const [selectedContactLists, setSelectedContactLists] = useState<
    Array<number>
  >([]);
  const [selectedPressLists, setSelectedPressLists] = useState<Array<number>>(
    []
  );
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [hide, setHide] = useState(false);
  const [subject, setSubject] = useState('');
  const context = useContext(AppContext);
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    const storedJWT = localStorage.getItem('jwt');

    if (storedJWT) {
      await fetch(process.env.REACT_APP_API_URL + '/send-emails.php', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + storedJWT,
        },
        body: JSON.stringify({
          contact_lists: selectedContactLists,
          press_lists: selectedPressLists,
          subject,
          message,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message === 'success') {
            setSuccess('Mail sent successfully');
            setContactLists([]);
            setPressLists([]);
            setHide(true);
          } else if (data.error) {
            setError(data.error);
          }
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setError('Failed, try again');
          setLoading(false);
        });
    } else {
      console.log('not logged in');
      localStorage.clear();
      context?.updateLoginStatus(false);
      navigate('/login');
    }
  };
  const getContactLists = async () => {
    const storedJWT = localStorage.getItem('jwt');

    await fetch(process.env.REACT_APP_API_URL + '/get-contact-lists.php', {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + storedJWT,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setContactLists(data);
        setLoading(false);
      })
      .catch((error) => {
        // Handle network or server errors
        console.log('error');
        console.log(error);
        setLoading(false);
      });
  };
  const getPressLists = async () => {
    const storedJWT = localStorage.getItem('jwt');

    await fetch(process.env.REACT_APP_API_URL + '/get-press-lists.php', {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + storedJWT,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPressLists(data);
        setLoading(false);
      })
      .catch((error) => {
        // Handle network or server errors
        console.log('error');
        console.log(error);
        setLoading(false);
      });
  };
  useEffect(() => {
    getContactLists();
    getPressLists();
  }, []);

  const toggleContactList = (id: number) => {
    if (selectedContactLists.includes(id)) {
      setSelectedContactLists(
        selectedContactLists.filter((list) => list !== id)
      );
    } else {
      setSelectedContactLists([...selectedContactLists, id]);
    }
  };
  const togglePressList = (id: number) => {
    if (selectedPressLists.includes(id)) {
      setSelectedPressLists(selectedPressLists.filter((list) => list !== id));
    } else {
      setSelectedPressLists([...selectedPressLists, id]);
    }
  };
  return (
    <div className="fixed top-0 left-0 z-10 grid content-center justify-center w-full h-full bg-black bg-opacity-30">
      <div className="inline-block w-auto p-10 bg-white rounded-lg w-full md:w-[400px]  relative">
        <h1 className="mb-5 uppercase">Select contact lists:</h1>
        <svg
          onClick={closeModal}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          className="absolute w-8 h-8 transition-all cursor-pointer fill-white stroke-red-500 -top-3 -right-3 hover:stroke-white hover:fill-red-500"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
          />
        </svg>
        {!hide && (
          <form onSubmit={handleSubmit}>
            <div className="mt-3 mb-5">
              <input
                id="subject"
                name="subject"
                type="subject"
                required
                placeholder="Subject"
                value={subject}
                onChange={(event) => setSubject(event.target.value)}
                className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-slate-500 focus:border-slate-500 sm:text-sm"
              />
            </div>
            <div className="mt-1">
              {contactLists.length > 0 && (
                <div className="flex flex-wrap gap-2">
                  {contactLists.map((list, index) => (
                    <a
                      key={index}
                      onClick={() => toggleContactList(list.ID)}
                      className={`flex cursor-pointer hover:opacity-90 text-xs items-center px-3 py-1 text-sm font-medium text-white rounded-full ${
                        selectedContactLists.includes(list.ID)
                          ? 'bg-green-500'
                          : 'bg-slate-400'
                      }`}
                    >
                      {list.name}
                    </a>
                  ))}
                </div>
              )}
              {pressLists.length > 0 && (
                <>
                  <div className="w-full h-px my-4 bg-slate-100"></div>
                  <div className="flex flex-wrap gap-2 ">
                    {pressLists.map((list, index) => (
                      <a
                        key={index}
                        onClick={() => togglePressList(list.ID)}
                        className={`flex cursor-pointer hover:opacity-90 text-xs items-center px-3 py-1 text-sm font-medium text-white rounded-full ${
                          selectedPressLists.includes(list.ID)
                            ? 'bg-green-500'
                            : 'bg-slate-400'
                        }`}
                      >
                        {list.name}
                      </a>
                    ))}
                  </div>
                </>
              )}
            </div>

            <div className="mt-7">
              {loading ? (
                <div className="flex justify-center">
                  <svg
                    className="inline-blockmt-5 w-9 h-9 text-accent animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </div>
              ) : (
                <button
                  type="submit"
                  className="flex justify-center w-auto px-6 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-default hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
                >
                  Send mail
                </button>
              )}
            </div>
          </form>
        )}
        <p className="mt-2 text-sm font-medium text-red-600">{error}</p>
        <p className="mt-2 text-sm font-medium text-green-600">{success}</p>
      </div>
    </div>
  );
};
export default SendMail;
