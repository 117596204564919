import type { FC } from 'react';

import { useState } from 'react';

import PublicFileBrowser from '../components/publicfilebrowser';
import SearchFiles from '../components/searchpublicfiles';

const Public: FC = () => {
  const [folderPath, setFolderPath] = useState<string>('/');

  const updateFolderPath = (path: string) => {
    setFolderPath(path);
  };

  return (
    <div className="max-w-[1200px] mx-auto">
      <div className="relative z-10 p-3 md:pt-10 md:p-5 lg:px-10 xl:px-16 fade-in">
        <div className="flex rounded-xl bg-slate-400">
          <div className="w-full lg:w-[50%] xl:w-[40%] p-5 md:p-10 lg:p-10 xl:py-12 xl:px-16">
            <h2 className="py-2 text-base text-white md:text-xl ">
              Welcome,
              <br />
              browse our brand or product images
            </h2>
            <div className="my-3 max-w-[220px] ">
              <SearchFiles
                updateFolderPath={updateFolderPath}
                path={folderPath}
              />
            </div>
          </div>
          <div className="relative  hidden lg:block lg:w-[50%] xl:w-[60%]">
            <img
              src="/welcome-page-public.jpg"
              alt=""
              className="absolute object-cover w-full h-full rounded-tr-xl rounded-br-xl"
            />
          </div>
        </div>
      </div>

      <PublicFileBrowser
        folderPath={folderPath}
        changeFolderPath={updateFolderPath}
      />
    </div>
  );
};
export default Public;
